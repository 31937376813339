import React, { useState, Fragment } from "react"
import { useEffectOnce } from "react-use"
import { useWindowWidth } from "@react-hook/window-size"
import forOwn from "lodash.forown"
import { graphql } from "gatsby"
import slug from "slug"
import shuffle from "lodash.shuffle"

/* Import Global Component(s) */
import SEO from "~components/seo"
import Page from "~components/page"

/* Import Local Component(s) */
import Image from "./components/image/_image"
import Audio from "./components/audio/_audio"
import MuxVideo from "./components/muxVideo/_muxVideo"
import Footer from "./components/footer/_footer"

/* Import Local Style(s) */
import "./trace.css"

const Trace = ({
  data: {
    allSanityProject: { edges: projects },
    allSanityPractice: { nodes: practices },
  },
}) => {
  const windowWidth = useWindowWidth()
  const [assets, setAssets] = useState([])

  practices = practices.reduce((accumulator, practice) => {
    accumulator[slug(practice.title)] = practice
    return accumulator
  }, {})

  // Get Assets from Projects
  useEffectOnce(() => {
    const assets = []
    projects.forEach(({ node: project }) => {
      project.documentation.forEach(documentation => {
        documentation.project = project
        // pick random image size
        if (windowWidth > 768) {
          documentation.size = ["small", "medium", "large"][
            Math.floor(Math.random() * 3)
          ]
        } else {
          documentation.size = ["small", "medium", "large", "extra-large"][
            Math.floor(Math.random() * 4)
          ]
        }
        assets.push(documentation)
      })
    })
    // randomize assets
    setAssets(shuffle(assets))
  })

  // Filters

  const [isActiveFilter, setIsActiveFilter] = useState(false)
  const [activeFilters, setActiveFilters] = useState({
    writing: false,
    curating: false,
    teaching: false,
    making: false,
    "living-archives": false,
  })

  const setNewFilter = filter => {
    const activeFiltersClone = Object.assign({}, activeFilters)
    forOwn(activeFiltersClone, function (value, key) {
      activeFiltersClone[key] = true
    })
    activeFiltersClone[filter] = false
    setIsActiveFilter(true)
    setActiveFilters(activeFiltersClone)
  }

  const clearFilters = () => {
    const activeFiltersClone = Object.assign({}, activeFilters)
    forOwn(activeFiltersClone, function (value, key) {
      activeFiltersClone[key] = false
    })
    setIsActiveFilter(false)
    setActiveFilters(activeFiltersClone)
  }

  const handleClick = filter => {
    if (isActiveFilter && activeFilters[filter] === false) {
      clearFilters()
    } else {
      clearFilters()
      setNewFilter(filter)
    }
  }

  return (
    <Fragment>
      <SEO title="Trace" />
      <Page page="trace">
        <div className="filters">
          <ul>
            <li className="curating">
              <button
                className={`${!activeFilters["curating"] ? "active" : ""}`}
                onClick={() => {
                  handleClick("curating")
                }}
                style={{
                  color: practices["curating"].color.hex,
                  borderBottomColor: practices["curating"].color.hex,
                }}
              >
                curating
              </button>
            </li>
            <li className="living-archives">
              <button
                className={`${
                  !activeFilters["living-archives"] ? "active" : ""
                }`}
                onClick={() => {
                  handleClick("living-archives")
                }}
                style={{
                  color: practices["living-archives"].color.hex,
                  borderBottomColor: practices["living-archives"].color.hex,
                }}
              >
                living archives
              </button>
            </li>   
            <li className="making">
              <button
                className={`${!activeFilters["making"] ? "active" : ""}`}
                onClick={() => {
                  handleClick("making")
                }}
                style={{
                  color: practices["making"].color.hex,
                  borderBottomColor: practices["making"].color.hex,
                }}
              >
                making
              </button>
            </li>
            <li className="teaching">
              <button
                className={`${!activeFilters["teaching"] ? "active" : ""}`}
                onClick={() => {
                  handleClick("teaching")
                }}
                style={{
                  color: practices["teaching"].color.hex,
                  borderBottomColor: practices["teaching"].color.hex,
                }}
              >
                teaching
              </button>
            </li>                                 
            <li className="writing">
              <button
                className={`${!activeFilters["writing"] ? "active" : ""}`}
                onClick={() => {
                  handleClick("writing")
                }}
                style={{
                  color: practices["writing"].color.hex,
                  borderBottomColor: practices["writing"].color.hex,
                }}
              >
                writing
              </button>
            </li>
          </ul>
        </div>
        <div className="assets">
          {assets.map(asset => {
            if (asset.hideOnTrace) {
              return null
            }
            if ((!asset.asset && !asset.video) || asset.hideOnTrace === true) {
              return null
            }
            switch (asset.__typename) {
              case "SanityDocumentationImage":
                return (
                  <Image
                    project={asset.project}
                    photoCredit={asset.photoCredit}
                    fluid={asset.asset.fluid}
                    caption={asset._rawCaption}
                    backgroundColor={asset.project.practiceReference.color.hex}
                    size={asset.size}
                    filtered={
                      activeFilters[slug(asset.project.practiceReference.title)]
                    }
                    key={asset._key}
                  />
                )
              case "SanityAudio":
                return (
                  <Audio
                    project={asset.project}
                    src={asset.asset.url}
                    caption={asset._rawCaption}
                    audioCredit={asset.audioCredit}
                    filtered={
                      activeFilters[slug(asset.project.practiceReference.title)]
                    }
                    key={asset._key}
                    size={asset.size}
                  />
                )
              case "SanityMuxSourceVideo":
                return (
                  <MuxVideo
                    playbackId={asset.video.asset.playbackId}
                    key={asset._key}
                    project={asset.project}
                    caption={asset._rawCaption}
                    videoCredit={asset.videoCredit}
                    filtered={
                      activeFilters[slug(asset.project.practiceReference.title)]
                    }
                    size={asset.size}
                  />
                )
              default:
                return null
            }
          })}
        </div>
        <Footer />
      </Page>
    </Fragment>
  )
}

export default Trace

export const query = graphql`
  query TraceQuery {
    allSanityProject(
      filter: { displayOnSite: { eq: true } }
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          title
          site
          practiceReference {
            title
            color {
              hex
            }
          }
          artists
          date
          documentation {
            ... on SanityAudio {
              _key
              _type
              asset {
                url
              }
              _rawCaption(resolveReferences: { maxDepth: 10 })
              audioCredit
              hideOnTrace
            }
            ... on SanityDocumentationImage {
              _key
              _type
              asset {
                url
                fluid {
                  ...GatsbySanityImageFluid_noBase64
                }
              }
              photoCredit
              _rawCaption(resolveReferences: { maxDepth: 10 })
              hideOnTrace
            }
            ... on SanityMuxSourceVideo {
              _key
              _type
              video {
                asset {
                  thumbTime
                  playbackId
                }
              }
              _rawCaption(resolveReferences: { maxDepth: 10 })
              videoCredit
              hideOnTrace
            }
          }
        }
      }
    }
    allSanityPractice {
      nodes {
        title
        desaturatedColor {
          hex
        }
        color {
          hex
        }
      }
    }
  }
`
