import React, { useState } from "react"
import { useWindowWidth } from "@react-hook/window-size"
import ReactPlayer from "react-player"
import sample from "lodash.sample"

/* Import Local Components */
import Description from "../description/_description"

/* Import Local Style(s) */
import "./mux-video.css"

const MuxVideo = ({ playbackId, filtered, project, videoCredit, size }) => {
  const windowWidth = useWindowWidth()
  const [marginTop] = useState(
    windowWidth > 768
      ? sample(["0", "4vh", "16vh", "32vh"])
      : sample(["0", "6vh", "9vh", "14vh"])
  )

  return (
    <div
      className={`trace trace-video ${filtered ? "filtered" : ""} ${size}`}
      style={{ marginTop }}
    >
      <div className="player-wrapper">
        <ReactPlayer
          className="react-player"
          playsinline
          controls
          pip={false}
          url={`https://stream.mux.com/${playbackId}.m3u8`}
          width="100%"
          height="100%"
        />
      </div>
      <Description project={project} credit={videoCredit} />
    </div>
  )
}

export default MuxVideo
