import React from "react"
import { Link } from "gatsby"
import slug from "slug"

import "./description.css"

const Description = ({ project, credit }) => {
  return (
    <div className="description">
      <Link
        to={`/p/${slug(project.title, { lower: true })}-${slug(project.site, {
          lower: true,
        })}`}
      >
        <span className="title">{project.title}</span>
        {credit ? (
          <>
            {" "}
            <span className="photo-credits">{credit}</span>
          </>
        ) : null}
      </Link>
    </div>
  )
}

export default Description
