import React, { useState } from "react"
import { useWindowWidth } from "@react-hook/window-size"

import { Link } from "gatsby"
import Img from "gatsby-image"
import slug from "slug"
import sample from "lodash.sample"

/* Import Local Components */
import Description from "../description/_description"

/* Import Local Style(s) */
import "./trace-image.css"

const DocumentationImage = ({
  project,
  photoCredit,
  fluid,
  alt,
  backgroundColor,
  filtered,
  size,
}) => {
  const windowWidth = useWindowWidth()
  const [marginTop] = useState(
    windowWidth > 768
      ? sample(["0", "4vh", "16vh", "32vh"])
      : sample(["0", "6vh", "9vh", "14vh"])
  )
  return (
    <div
      className={`trace trace-image ${filtered ? "filtered" : ""} ${size}`}
      style={{
        marginTop: marginTop,
      }}
    >
      <Link
        to={`/p/${slug(project.title, { lower: true })}-${slug(project.site, {
          lower: true,
        })}`}
      >
        <Img
          fluid={fluid}
          alt={alt ? alt : ""}
          backgroundColor={backgroundColor}
        />
      </Link>
      <Description project={project} credit={photoCredit} />
    </div>
  )
}

export default DocumentationImage
